import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import htmlescape from 'htmlescape';
import CachedMessage from '@rubyapps-mvc/cached-message';
import GoogleTagManager from '@rubyapps-mvc/google-tag-manager';
import { gtmTrackLinkClicks, openExternalLinksInNewTab } from '@rubyapps-mvc/body-events';
import { connect } from 'react-redux';
import Favicons from '../elements/Favicons.jsx';
import OpenGraph from '../elements/OpenGraph.jsx';
import KnowledgeGraph from '../elements/KnowledgeGraph.jsx';
import SitelinksSearch from '../elements/SitelinksSearch.jsx';
import BreadcrumbList from '../elements/BreadcrumbList.jsx';
import Credits from '../elements/Credits.jsx';
import Footer from '../elements/Footer/index.jsx';
import HeaderMessage from '../elements/HeaderMessage.jsx';
import Banner, { FULL_BLEED, MAX_WIDTH_1440 } from '../elements/Merger/Banner/index.jsx';
import PopupAnnouncement from '../elements/Merger/PopupAnnouncement/index.jsx';
import { qualifyUrlInWindow } from '../../utils/url';
import { withUrlsQualified } from '../hocs';
import CookieHandlerContext from '../context/CookieHandlerContext';
import { PDF_RENDER_MODE } from '../../constants';
import './default.scss';
import stickyFooterStyles from '../../scss/modules/sticky-footer.scss';

import bannerData from "../elements/Merger/data/banner.json";
import popupAnnouncementData from "../elements/Merger/data/popup.json";

function safeStringify(obj) {
    // Return JSON string that is properly encoded
    return htmlescape(obj);
}

const mapStateToHTMLOrBodyProps = state => ({
    lockScroll: state.nav.isOpen,
});

const HTMLTag = connect(mapStateToHTMLOrBodyProps)(({ lockScroll, children }) => {
    const style = {};
    if (lockScroll) {
        style.overflow = 'hidden';
        style.position = 'fixed';
        style.top = 0;
        style.left = 0;
        style.width = '100%';
        style.height = '100%';
    }

    // XXX DO NOT set a className on <html>. Non-React scripts (Typekit, Modernizr,
    // Detectizr) need to be able to add classes without them accidentally being
    // overwritten by React. React will leave the class alone as long as we don't
    // modify that particular prop.
    return (
        <html lang="en" style={style}>
            {children}
        </html>
    );
});


function initMailtoDisclaimer(ev, window) {
    // we want every email link on the site to prompt the user to accept the terms of this disclaimer
    const targetLink = ev.target.href;

    if (!targetLink) {
        return;
    }

    if (targetLink.indexOf('mailto') === 0) {
        ev.preventDefault();

        const agree = window.confirm('Before communicating with Troutman Pepper Locke or its attorneys through this website, please read the Disclaimer and Privacy Policy. Please do not send any private or confidential information in your inquiry. No attorney-client relationship exists between you and Troutman Pepper Locke until you have received a written statement that the firm represents you in a particular matter. By submitting your inquiry, you affirm that you have read and agree to these terms.');

        if (agree) {
            window.location = targetLink;
        }
    }
}

function handleBodyClickEvents(ev, window) {
    gtmTrackLinkClicks(ev, window);
    openExternalLinksInNewTab(ev, window);
    initMailtoDisclaimer(ev, window);
}

const BodyTag = connect(mapStateToHTMLOrBodyProps)(({
    lockScroll,
    window,
    children,
    renderMode,
}) => {
    const style = {};
    if (lockScroll) {
        style.overflow = 'hidden';
    }

    const bodyClasses = [stickyFooterStyles.body];
    if (renderMode === PDF_RENDER_MODE) {
        bodyClasses.push('pdf-render-mode');
    } else {
        bodyClasses.push('default-render-mode');
    }

    /* eslint-disable jsx-a11y/click-events-have-key-events */
    /* eslint-disable jsx-a11y/no-static-element-interactions */
    return (
        <body
            className={bodyClasses.join(' ')}
            style={style}
            onClick={ev => handleBodyClickEvents(ev, window)}>
            {children}
        </body>
    );
    /* eslint-enable jsx-a11y/click-events-have-key-events */
    /* eslint-enable jsx-a11y/no-static-element-interactions */
});

class DefaultLayout extends React.Component { // eslint-disable-line react/prefer-stateless-function
    render() {
        const {
            window,
        } = this.props;

        const isHomepage = _.get(this, 'props.children.props.templateKeyword') === 'homepage';
        const isMergerPage = _.get(this, 'props.children.props.templateKeyword') === 'merger';
        const isErrorPage = _.get(this, 'props.children.props.templateKeyword') === 'error_page';
        const showMergerBanner = !isErrorPage && !isMergerPage;
        const showMergerPopup = Boolean(_.get(this, 'props.showMergerPopup'));

        const initialProps = {
            __html: safeStringify(_.omit(this.props.initialProps, ['window'])),
        };

        const devRegExp = /troutman\.dev$/;
        const canonicalUrl = _.get(this.props, 'canonicalUrl');

        return (
            <HTMLTag>
                <head>
                    <meta charSet="utf-8" />
                    <meta name="viewport" content="width=device-width, initial-scale=1.0, user-scalable = no, maximum-scale=1.0" />
                    <meta httpEquiv="X-UA-Compatible" content="IE=Edge" />

                    <Credits {...this.props.credits} />

                    <Favicons window={window} />
                    <meta name="google" content="notranslate" />
                    <meta name="twitter:card" content="summary_large_image" />

                    <OpenGraph properties={this.props.openGraphProps} />
                    <KnowledgeGraph
                        {...this.props.knowledgeGraphProps}
                        window={window}
                    />
                    <SitelinksSearch
                        searchUrl={qualifyUrlInWindow(this.props.searchUrl, window)}
                        siteUrl={this.props.siteUrl}
                    />
                    <BreadcrumbList breadcrumbs={this.props.breadcrumbs} />
                    {canonicalUrl && <link rel="canonical" href={canonicalUrl} />}
                    <meta name="description" content={this.props.metaDescription} />
                    <meta name="keywords" content={this.props.metaKeywords} />
                    <title>{this.props.browserPageTitle}</title>

                    <link rel="stylesheet" href="https://use.typekit.net/zmo5miw.css" />
                    {this.props.styleAssetPaths.map((stylePath, idx) =>
                        <link rel="stylesheet" type="text/css" href={qualifyUrlInWindow(stylePath, window)} key={idx} />)}
                </head>
                <BodyTag window={window} renderMode={this.props.renderMode} >
                    <GoogleTagManager gtmContainerId={this.props.gtmContainerId} doCookieCheck={false} />
                    <div id="app" className={stickyFooterStyles.wrapper}>
                        <HeaderMessage headerMessage={this.props.headerMessage} />
                        {showMergerPopup && <PopupAnnouncement {...popupAnnouncementData} />}
                        <div className={stickyFooterStyles.content}>
                            {this.props.children}
                        </div>
                        <Footer footerProps={this.props.footerProps} isHomepage={isHomepage} />
                    </div>
                    <CookieHandlerContext.Consumer>
                        {cookieHandler => (
                            <CachedMessage
                                window={window}
                                cookieHandler={cookieHandler}
                                devRegExp={devRegExp} />
                        )}
                    </CookieHandlerContext.Consumer>
                    <script
                        dangerouslySetInnerHTML={{
                            __html: `
                                ! function(r) {
                                    var i = document.createElement("img");
                                    i.setAttribute("src","//troutmanpepper.vuturevx.com/security/tracker.gif?referer=" + encodeURIComponent(r))
                                }(document.referrer);`,
                        }}
                    />
                    <script
                        id="initial-props"
                        type="application/json"
                        dangerouslySetInnerHTML={initialProps}
                    />
                    {this.props.scriptAssetPaths.map((scriptPath, idx) =>
                        <script src={qualifyUrlInWindow(scriptPath, window)} key={idx} />)}
                </BodyTag>
            </HTMLTag>
        );
    }
}

DefaultLayout.propTypes = {
    styleAssetPaths: PropTypes.arrayOf(PropTypes.string),
    scriptAssetPaths: PropTypes.arrayOf(PropTypes.string),
    initialProps: PropTypes.object, // eslint-disable-line react/forbid-prop-types
    window: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    children: PropTypes.node,
    credits: PropTypes.shape(Credits.propTypes),
    templateKeyword: PropTypes.string,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    browserPageTitle: PropTypes.string,
    metaDescription: PropTypes.string,
    metaKeywords: PropTypes.string,
    openGraphProps: PropTypes.shape(OpenGraph.propTypes),
    knowledgeGraphProps: PropTypes.shape(_.omit(KnowledgeGraph.propTypes, ['window'])),
    searchUrl: PropTypes.string,
    siteUrl: PropTypes.string,
    breadcrumbs: BreadcrumbList.propTypes.breadcrumbs, // eslint-disable-line react/no-typos
    gtmContainerId: PropTypes.string,
    cookieBannerText: PropTypes.string,
    footerProps: PropTypes.shape(Footer.propTypes),
    rublyticsId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    renderMode: PropTypes.string,
    headerMessage: PropTypes.string,
    showMergerPopup: PropTypes.bool,
    canonicalUrl: PropTypes.string,
};

DefaultLayout.defaultProps = {
    styleAssetPaths: [],
    scriptAssetPaths: [],
    initialProps: {},
    children: null,
    credits: {},
    templateKeyword: '',
    id: '',
    browserPageTitle: '',
    metaDescription: '',
    metaKeywords: '',
    openGraphProps: {},
    knowledgeGraphProps: {},
    searchUrl: '',
    siteUrl: '',
    breadcrumbs: [],
    gtmContainerId: '',
    cookieBannerText: '',
    footerProps: {},
    rublyticsId: '',
    renderMode: '',
    headerMessage: '',
};

export default DefaultLayout;
